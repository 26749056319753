@tailwind base;
@tailwind components;
@tailwind utilities;

body {
 margin: 0;
 font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}

code {
 font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
 @apply text-gray-100 font-semibold text-xl;
}

h2 {
 @apply text-gray-800 font-semibold text-lg;
}

h3 {
 @apply text-gray-700 font-semibold text-sm;
}

.youtubeContainer {
 position: relative;
 width: 100%;
 height: 0;
 padding-bottom: 56.25%;
 overflow: hidden;
 margin-bottom: 50px;
}

.youtubeContainer iframe {
 width: 100%;
 height: 100%;
 position: absolute;
 top: 0;
 left: 0;
}
